import React from "react";
import { Link } from "react-router-dom";
import Logos from "../Logos";
import Icons from "./sub/Icons";

const Footer = ({ rootFolder }) => {
  return (
    <div style={{ marginTop: 'auto' }}>
      <hr />
      <Logos rootFolder={rootFolder} />
      <div className="footer-wrapper">
        <div className="footer">
          <div className="footer__columns">
            <div className="footer__column">
              <div className="footer__title">Uuri lähemalt</div>
              <div className="footer__links-container">
                <div>
                  <Link className="footer__link" to="/instructions">
                    Kasutusjuhend
                  </Link>
                </div>
                <div>
                  <Link className="footer__link" to="/article/et-EE">
                    Kliendilood
                  </Link>
                </div>
                <div>
                  <Link className="footer__link" to="/frequently-asked-questions">
                    Korduvad küsimused
                  </Link>
                </div>
                <div>
                  <Link className="footer__link" to="/advertisement">
                    Reklaam
                  </Link>
                </div>
              </div>
            </div>
            <div className="footer__column">
              <div className="footer__title">Dokumendid</div>
              <div className="footer__links-container">
                <div>
                  <Link className="footer__link" to="/terms-and-conditions">
                    Kasutustingimused
                  </Link>
                </div>
                <div>
                  <Link className="footer__link" to="/privacy-policy">
                    Privaatsustingimused
                  </Link>
                </div>
              </div>
            </div>
            <div className="footer__column">
              <div className="footer__title">Kontakt</div>
              <div className="footer__links-container">
                <div>
                  <a className="footer__link-email" href="mailto:info@turgoil.com">info@turgoil.com</a>
                </div>
                <div className="footer__text">
                  Turgoil OÜ
                </div>
                <div className="footer__text">
                  Registrikood: 17106458
                </div>
              </div>
            </div>
          </div>
          <div className="footer__logo">
            <Link to="/">
              {Icons.TurgoilLogo}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;