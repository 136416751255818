import React, { PureComponent } from "react";
import Big from "big.js";
import { produce } from "immer";
import { connect } from "react-redux";
import { Button, Form } from "react-bootstrap";

import * as toastActions from "../../../state/toasts";
import { ToastType } from "../../../common/Toasts";
import {
  createOilRequestBid,
  editOilRequestBid,
} from "../../../services/turgoil-api";
import BidForm, { Bid } from "./BidForm";
import { OilRequestOilType } from "../../../utils/config";
import PageSectionTitle from "../../../common/PageSectionTitle";
import { getNumberDisplayValue, getNumberDisplayValueVatIncluded } from "../../../utils/format";

function calculateTotalPrice(bids, oils) {
  let total = new Big(0);

  oils.forEach((oil) => {
    const oilBid = bids.find((oBid) => oil.id === oBid.oilId);
    if (oilBid.price) {
      total = total.plus(new Big(oilBid.price || 0).times(oil.amount));
    }
  });

  return total.toFixed(2);
}

const Mode = {
  Edit: "Edit",
  View: "View",
};

function getMode(user, oilRequestBids) {
  const bid = oilRequestBids.find(
    (oilRequestBid) => oilRequestBid.companyId === user.bidderInfo.companyId,
  );
  if (bid) {
    return Mode.Edit;
  }

  return Mode.View;
}

type MainBid = {
  id?: string;
  bids: Bid[];
  price: string;
  comment: string;
  errors: Record<string, Partial<Record<keyof Omit<Bid, "oilId">, string>>>;
};

type State = {
  mainBids: MainBid[];
  termsAccept: boolean;
  termsError: boolean;
};

function getInitialState(
  user: any,
  oils: any[],
  bids: any[],
): State {
  if (user.role === "Bidder") {
    const hasBid = bids.some(
      (b) => b.companyId === user.bidderInfo.companyId,
    );
    if (hasBid) {
      return {
        termsAccept: false,
        termsError: false,
        mainBids: bids
          .filter((b) => b.companyId === user.bidderInfo.companyId)
          .map((b) => ({
            id: b.id,
            price: b.price,
            bids: oils.map((o) => (
              b.oilBids.find((ob) => ob.oilId === o.id)
            )),
            errors: {},
            comment: b.comment,
          }))
      }
    }
  }

  return {
    termsAccept: false,
    termsError: false,
    mainBids: [{
      price: "0",
      errors: {},
      comment: "",
      bids: oils.map((o) => ({
        price: "",
        oilId: o.id,
        marking: o.marking,
      })),
    }]
  };
}

type Props = {
  user: any;
  oilRequest: any;
  oilRequestBids: any[];
  updateOilRequest: () => void;
  showToast: typeof toastActions.showToast;
};


class CreateOrEditBid extends PureComponent<Props, State> {
  private mode: string;

  constructor(props: Readonly<Props>) {
    super(props);

    this.mode = getMode(props.user, props.oilRequestBids);

    this.state = getInitialState(
      props.user,
      props.oilRequest.oils,
      props.oilRequestBids,
    );
  }

  private validate = (): boolean => {
    const { mainBids, termsAccept } = this.state;
    let hasError = false;
    let termsError = false;

    const nextMainBids = produce(
      mainBids,
      (draftMainBids: MainBid[]) => {
        draftMainBids.forEach((draftMainBid) => {
          for (const bid of draftMainBid.bids) {
            draftMainBid.errors[bid.oilId] = {};
            if (!bid.price) {
              draftMainBid.errors[bid.oilId].price = "See väli on kohustuslik.";
            }
            if (bid.cloudPoint && parseInt(bid.cloudPoint, 10) > 100) {
              draftMainBid.errors[bid.oilId].cloudPoint = "Väärtus peab olema väiksem 100";
            }
            if (bid.cloudPoint && parseInt(bid.cloudPoint, 10) < -100) {
              draftMainBid.errors[bid.oilId].cloudPoint = "Väärtus peab olema suurem -100";
            }
            if (
              bid.coldFilterPlugPoint &&
              parseInt(bid.coldFilterPlugPoint, 10) > 100
            ) {
              draftMainBid.errors[bid.oilId].coldFilterPlugPoint =
                "Väärtus peab olema väiksem 100";
            }
            if (
              bid.coldFilterPlugPoint &&
              parseInt(bid.coldFilterPlugPoint, 10) < -100
            ) {
              draftMainBid.errors[bid.oilId].coldFilterPlugPoint =
                "Väärtus peab olema suurem -100";
            }
          }
          hasError = hasError || Object.values(draftMainBid.errors).some((e) => Object.keys(e).length !== 0);
        })
      },
    );

    if (this.showTermsAndConditions() && !termsAccept) {
      termsError = true;
    }

    this.setState({ mainBids: nextMainBids, termsError });
    return !hasError && !termsError;
  };

  private showTermsAndConditions = (): boolean => {
    const { mainBids } = this.state;
    const { user } = this.props;
    return !mainBids.some((mb) => !!mb.id) && !user.bidderInfo.contract;
  }

  private isCloudPoint = (dirtyBid: Bid): boolean => {
    const { oilRequest } = this.props;
    const oil = oilRequest.oils.find((o) => o.id === dirtyBid.oilId);
    return (
      ([
        String(OilRequestOilType.DieselSpecial),
        String(OilRequestOilType.Diesel),
      ].includes(oil.oilType) &&
        ["A0", "A1", "A2", "HVO"].includes(dirtyBid.marking)) ||
      oil.oilType === OilRequestOilType.HydrotreatedVegetableOil
    );
  };

  private isColdFilterPlugPoint = (dirtyBid: Bid): boolean => {
    const { oilRequest } = this.props;
    const oil = oilRequest.oils.find((o) => o.id === dirtyBid.oilId);
    return (
      ([
        String(OilRequestOilType.DieselSpecial),
        String(OilRequestOilType.Diesel),
      ].includes(oil.oilType) &&
        ["E", "F", "A0", "A1", "A2", "HVO"].includes(dirtyBid.marking)) ||
      oil.oilType === OilRequestOilType.HydrotreatedVegetableOil
    );
  };

  private createOrEditBid = (event: React.MouseEvent) => {
    const { oilRequest, showToast } = this.props;
    // const { price, comment, mainBids: bids } = this.state;
    event.preventDefault();

    if (!this.validate()) {
      return;
    }

    const { mainBids } = this.state;
    const promises: Promise<any>[] = [];
    mainBids.forEach((mainBid, i) => {
      const { price, comment, bids, id } = mainBid;

      const request: {
        price: string;
        comment: string;
        oilBids: Bid[];
      } = {
        price,
        comment,
        oilBids: bids.map((b) => ({
          cloudPoint: this.isCloudPoint(b)
            ? b.cloudPoint || undefined
            : undefined,
          coldFilterPlugPoint: this.isColdFilterPlugPoint(b)
            ? b.coldFilterPlugPoint || undefined
            : undefined,
          price: b.price || undefined,
          oilId: b.oilId || undefined,
          marking: b.marking || undefined,
        })),
      };

      if (!id) {
        promises.push(createOilRequestBid(oilRequest.id, request));
      } else {
        promises.push(editOilRequestBid(oilRequest.id, mainBid.id, request));
      }
    });
    Promise.all(promises).then(() => {
      this.props.updateOilRequest();
      showToast({
        title: "Päring õnnestus.",
        text: mainBids.some((mb) => mb.id) ? `Pakkumine edukalt muudetud!` : `Pakkumine edukalt loodud!`,
        type: ToastType.Success,
      });
    }).catch((err) => {
      showToast({
        title: "Päring ebaõnnestus.",
        text: err.message,
        type: ToastType.Error,
      });
    })


  };

  private handleBidChange = (bid: Bid, i: number) => {
    const { oilRequest } = this.props;
    const { mainBids } = this.state;

    const nextMainBids = produce(
      mainBids,
      (draftMainBids: MainBid[]) => {
        const draftMainBid = draftMainBids[i];
        const index = draftMainBid.bids.findIndex((b) => b.oilId === bid.oilId);
        draftMainBid.bids[index] = bid;
        draftMainBid.price = calculateTotalPrice(draftMainBid.bids, oilRequest.oils);
        draftMainBid.errors[bid.oilId] = undefined;
      },
    );

    this.setState({ mainBids: nextMainBids });
  };

  private handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>, mainBidIndex: number) => {
    const { mainBids } = this.state;
    const nextMainBids = produce(
      mainBids,
      (draftMainBids: MainBid[]) => {
        const draftMainBid = draftMainBids[mainBidIndex];
        draftMainBid.comment = e.target.value;
      },
    );

    this.setState({ mainBids: nextMainBids });
  };

  private createMainBid = () => {
    const { oilRequest } = this.props;
    this.setState((prevState) => ({
      mainBids: [...prevState.mainBids, {
        price: "0",
        errors: {},
        comment: "",
        bids: oilRequest.oils.map((o) => ({
          price: "",
          oilId: o.id,
          marking: o.marking,
        })),
      }]
    }))
  }

  private handleTermsToggle = () => {
    this.setState((prevState) => ({
      termsAccept: !prevState.termsAccept,
      termsError: false,
    }));
  };

  render() {
    const { mainBids } = this.state;
    const { oilRequest, user } = this.props;

    return (
      <>
        <div className="createOrEditBid">
          <PageSectionTitle>Pakkumine</PageSectionTitle>
          <div className="createOrEditBid__formFields">
            {mainBids.map((mainBid, mainBidIndex) => (
              <div key={mainBidIndex}>
                {mainBids.length > 1 && (
                  <div className="createOrEditBid__formFields__subTitle">
                    Pakkumine nr {mainBidIndex + 1}
                  </div>
                )}
                {
                  mainBid.bids.map((bid) => (
                    <div>
                      <BidForm
                        key={bid.oilId}
                        oil={{ oilType: oilRequest.oils.find((o) => o.id === bid.oilId)?.oilType }}
                        index={mainBidIndex}
                        bid={bid}
                        onChange={this.handleBidChange}
                        errors={mainBid.errors[bid.oilId]}
                      />
                    </div>
                  ))
                }
                <div>
                  <Form.Group >
                    <Form.Label className="createOrEditBid__formFields__oilSection__comment_label">
                      Jäta kommentaar
                    </Form.Label>
                    <textarea
                      style={{ fontSize: '14px', background: '#F7F7F7', minHeight: '100px' }}
                      onChange={(e) => this.handleCommentChange(e, mainBidIndex)}
                      name="comment"
                      value={mainBid.comment}
                      className="form-control"
                    />
                  </Form.Group>
                </div>

                <div className="createOrEditBid__formFields__oilSection__sumContainer">
                  <Form.Group className="createOrEditBid__formFields__oilSection__sumRow">
                    <Form.Label className="createOrEditBid__formFields__oilSection__sumLabel">
                      Pakkumine ilma käibemaksuta
                    </Form.Label>
                    <Form.Control
                      value={getNumberDisplayValue(mainBid.price, 2) + " €"}
                      plaintext
                      className="createOrEditBid__formFields__oilSection__sumValue"
                    />
                  </Form.Group>
                  <Form.Group className="createOrEditBid__formFields__oilSection__sumRow">
                    <Form.Label style={{ fontSize: '14px' }} className="createOrEditBid__formFields__oilSection__sumLabel">
                      Pakkumine koos käibemaksuga
                    </Form.Label>
                    <Form.Control
                      value={getNumberDisplayValueVatIncluded(mainBid.price, 2) + " €"}
                      plaintext
                      className="createOrEditBid__formFields__oilSection__sumValue"
                      style={{ fontSize: '16px', fontWeight: '700' }}
                    />
                  </Form.Group>
                </div>
              </div>
            ))}
            {this.showTermsAndConditions() && (
              <Form.Group>
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    onClick={this.handleTermsToggle}
                    checked={this.state.termsAccept}
                    isInvalid={this.state.termsError}
                  />
                  <Form.Check.Label>
                    <span style={{ fontSize: '14px', fontFamily: 'DM Sans' }}>
                      Olen lugenud ja nõustun Turgoil.com&nbsp;
                      <a
                        onClick={() => window.open("https://turgoil.com/terms-and-conditions", "_blank", "noopener noreferrer nofollow")}
                        className="create-request__accept-terms-link"
                      >
                        kasutustingimustega
                      </a>
                      &nbsp;<br />ning arvestanud pakkumust tehes platvormi vahendustasuga, kui meid valitakse hanke võitjaks.
                    </span>
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            )}

            <button
              className="createOrEditBid__formFields__oilSection__btn"
              type="submit"
              onClick={this.createOrEditBid}
            >
              Salvesta
            </button>

            {mainBids.length < 2 && (
              <Button type="button" className="createOrEditBid__formFields__btnAdd" onClick={this.createMainBid}>
                + Lisapakkumine
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showToast: (toast) => dispatch(toastActions.showToast(toast)),
  };
}

export default connect(null, mapDispatchToProps)(CreateOrEditBid);
