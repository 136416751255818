import React from "react";

const Logos = ({ rootFolder }) => {
  return (
    <div className="logos">
      <div>
        <img
          className="logos__icon_hepa"
          src={rootFolder + "images/svg/Hepa-logo.svg"}
          alt="Hepa logo"
        />
      </div>
      <div>
        <img
          className="logos__icon_jetoil"
          src={rootFolder + "images/jpg/Jetoil-logo.jpg"}
          alt="Jetoil logo"
        />
      </div>
      <div>
        <img
          className="logos__icon_markoil"
          src={rootFolder + "images/jpg/Markoil-logo.jpg"}
          alt="Markoil logo"
        />
      </div>
      <div >
        <img
          className="logos__icon_neste"
          src={rootFolder + "images/svg/Neste-logo.svg"}
          alt="Neste logo"
        />
      </div>
      <div>
        <img
          className="logos__icon_terminal"
          src={rootFolder + "images/png/Terminal-logo.png"}
          alt="Terminal logo"
        />
      </div>
    </div>
  );
}

export default Logos;