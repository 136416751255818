import queryString from "query-string";
import { post, get, put, ApiResponse } from "./http";
import { ApiErrorResponse } from "./http";

/**
 * @param {Object} res
 * @param {number} res.status
 * @param {string} res.type
 * @param {string} res.detail
 */
function createError(res: ApiResponse): ApiErrorResponse {
  if (res.data && res.data.status && res.data.type && res.data.detail) {
    const error: Partial<ApiErrorResponse> = new Error(res.data.detail);
    error.status = res.data.status;
    error.type = res.data.type;
    return error as ApiErrorResponse;
  }

  const error: Partial<ApiErrorResponse> = new Error("Ootamatu viga.");
  error.type = "https://turgoil.com/problems/unexpected";
  return error as ApiErrorResponse;
}

function reject(res: ApiResponse): never {
  throw createError(res);
}


/**
 * Get landing-page statistics.
 */
export async function getLandingPageStatistics() {
  return get(`/landing-page/statistics`)
    .then((res) => res.data)
    .catch(reject);
}


/**
 * Create person.
 *
 * @param {Object} body
 * @param {string} body.email
 * @param {string} body.password
 * @param {string} body.surname
 * @param {string} body.forename
 */
export async function createUser(req) {
  return post("/users", req).catch(reject);
}

/**
 * Get user notifications.
 *
 * @param {number} userId
 * @param {boolean} read
 */
export async function getUserNotifications(userId, read) {
  return get(`/users/${userId}/notifications?read=${read}`)
    .then((res) => res.data.notifications)
    .catch(reject);
}

/**
 * Get oil request conversations.
 */
export async function getOilRequestConversations() {
  return get("/oil-request-conversations")
    .then((res) => res.data.conversations)
    .catch(reject);
}

/**
 * Get oil request conversation.
 *
 * @param {string} conversationId
 */
export async function getOilRequestConversation(conversationId) {
  return get(`/oil-request-conversations/${conversationId}`)
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Create oil request conversation message.
 *
 * @param {string} conversationId
 */
export async function createOilRequestConversationMessage(
  conversationId,
  message,
) {
  return post(`/oil-request-conversations/${conversationId}/messages`, {
    message,
  })
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Activates user account.
 *
 * @param {string} activationCode
 */
export async function activateAccount(activationCode) {
  return post("/users/activate", { activationCode }).catch(reject);
}

export async function getUser(userId: string): Promise<ApiResponse> {
  return get(`/users/${userId}`).catch(reject);
}

/**
 * @param {number} oilRequestId
 */
export async function getOilRequestBids(oilRequestId) {
  return get(`/oil-requests/${oilRequestId}/bids`)
    .then((res) => res.data.bids)
    .catch(reject);
}

/**
 * Creates oil request.
 *
 * @param {Object} req
 * @return {Promise<Object>}
 */
export async function createOilRequest(req) {
  return post("/oil-requests", req).catch(reject);
}

/**
 * Gets oil requests.
 */
export async function getOilRequests(params) {
  return get(
    `/oil-requests?${queryString.stringify(params, { arrayFormat: "comma" })}`,
  )
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Gets oil requests.
 */
export async function getOilRequest(id) {
  return get(`/oil-requests/${id}`)
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Gets user companies.
 *
 * @param {number} userId
 */
export async function getUserCompanies(userId): Promise<ApiResponse> {
  return get(`/users/${userId}/companies`).catch(reject);
}

/**
 * Get bidders.
 */
export async function getBidders(): Promise<ApiResponse> {
  return get(`/bidders`).catch(reject);
}

/**
 * Authenticate with password.
 *
 * @param {string} email
 * @param {string} password
 */
export async function authenticationProvidersPassword(
  email,
  password,
): Promise<string> {
  return post("/authentication-providers/password", {
    email,
    password,
  })
    .then((res) => res.data.token)
    .catch((res) => {
      const err: ApiErrorResponse & {
        lockDatetime?: string;
      } = createError(res);
      if (res.data && res.data.lockDatetime) {
        err.lockDatetime = res.data.lockDatetime;
      }

      throw err;
    });
}

/**
 * Authenticate with facebook.
 *
 * @param {string} accessToken
 */
export async function authenticationProvidersFacebook(accessToken) {
  return post("/authentication-providers/facebook", {
    accessToken,
  })
    .then((res) => res.data.token)
    .catch(reject);
}

/**
 * Authenticate with google.
 *
 * @param {string} accessToken
 */
export async function authenticationProvidersGoogle(accessToken) {
  return post("/authentication-providers/google", {
    accessToken,
  })
    .then((res) => res.data.token)
    .catch(reject);
}

/**
 * @param {Object} query
 * @param {string} query.registryCode
 */
export async function getRegistryCompanies(query) {
  const queryParams = new URLSearchParams(query);
  return get(`/registry-companies?${queryParams.toString()}`)
    .then((res) => res.data.companies)
    .catch(reject);
}

/**
 * @param {Object} query
 * @param {string} query.companyIds
 */
export async function getCompanies(query) {
  const queryParams = new URLSearchParams(query);
  return get(`/companies?${queryParams.toString()}`)
    .then((res) => res.data.companies)
    .catch(reject);
}

/**
 * Activates user account.
 *
 * @param {string} activationCode
 */
export async function activateCompany(activationCode) {
  return post("/companies/activate", { activationCode }).catch(reject);
}

/**
 * @param {Object} query
 * @param {string} query.userIds
 */
export async function getUsers(query) {
  const queryParams = new URLSearchParams(query);
  return get(`/users?${queryParams.toString()}`)
    .then((res) => res.data.users)
    .catch(reject);
}

/**
 * @param {number} userId
 * @param {Object} body
 * @param {string} body.registryCode
 * @param {string} body.contactPhone
 * @param {string} body.contactEmail
 */
export async function createUserCompany(userId, body) {
  return post(`/users/${userId}/companies`, body)
    .then((res) => res.data)
    .catch(reject);
}

/**
 * @param {number} userId
 * @param {number} companyId
 * @param {Object} body
 * @param {string} body.contactPhone
 * @param {string} body.contactEmail
 */
export async function editUserCompany(userId, companyId, body) {
  return put(`/users/${userId}/companies/${companyId}`, body).catch(reject);
}

/**
 * @param {number} oilRequestId
 * @param {Object} body
 * @param {string} body.comment
 * @param {Array<Object>} body.oilBids
 * @param {string} body.oilBids.price
 * @param {number} body.oilBids.oilId
 */
export async function createOilRequestBid(oilRequestId, body) {
  return post(`/oil-requests/${oilRequestId}/bids`, body)
    .then((res) => res.data)
    .catch(reject);
}

/**
 * @param {number} oilRequestId
 * @param {Object} body
 * @param {number} body.bidId
 * @param {string} body.markup
 */
export async function setOilRequestWinner(oilRequestId, body) {
  return put(`/oil-requests/${oilRequestId}/winner`, body).catch(reject);
}

/**
 * @param {number} oilRequestId
 * @param {number} bidId
 * @param {Object} body
 * @param {string} body.comment
 * @param {Array<Object>} body.oilBids
 * @param {string} body.oilBids.price
 * @param {number} body.oilBids.oilId
 */
export async function editOilRequestBid(oilRequestId, bidId, body) {
  return put(`/oil-requests/${oilRequestId}/bids/${bidId}`, body)
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Creates request.
 *
 * @param {object} data
 * @return {Promise<Object>}
 */
export async function createRequest(data) {
  return post("/requests", data).catch(reject);
}

/**
 * Gets requests.
 */
export async function getRequests(searchParams) {
  return get(`/requests${searchParams}`)
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Gets requester requests.
 */
export async function getRequesterRequests(searchParams) {
  return get(`/requests/requester${searchParams}`)
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Gets bidder requests.
 */
export async function getBidderRequests(searchParams) {
  return get(`/requests/bidder${searchParams}`)
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Creates bid.
 *
 * @param {number} requestId
 * @param {object} data
 */
export async function createBid(requestId, data) {
  return post(`/requests/${requestId}/bid`, data).catch(reject);
}

/**
 * Modify bid.
 *
 * @param {number} requestId
 * @param {object} data
 */
export async function modifyBid(requestId, data) {
  return put(`/requests/${requestId}/bid`, data).catch(reject);
}

/**
 * Sets request winner.
 *
 * @param {string} requestId
 * @param {number} bidId
 */
export async function setRequestWinner(requestId, bidId) {
  return put(`/requests/${requestId}/winner`, { bidId })
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Creates request question.
 *
 * @param {number} requestId
 * @param {string} question
 */
export async function getOilRequestQuestions(oilRequestId) {
  return get(`/oil-requests/${oilRequestId}/questions`)
    .then((res) => res.data.questions)
    .catch(reject);
}

/**
 * Creates request question.
 *
 * @param {number} requestId
 * @param {string} question
 */
export async function createOilRequestQuestion(oilRequestId, question) {
  return post(`/oil-requests/${oilRequestId}/questions`, { question }).catch(
    reject,
  );
}

/**
 * Creates request question answer.
 *
 * @param {number} requestId
 * @param {Object} req
 */
export async function answerOilRequestQuestion(oilRequestId, questionId, req) {
  return put(
    `/oil-requests/${oilRequestId}/questions/${questionId}`,
    req,
  ).catch(reject);
}

/**
 * Gets person information.
 */
export async function getPersonInformation() {
  return get("/person")
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Changes user password.
 *
 * @param {string} userId
 * @param {Object} req
 * @param {string} req.password
 * @param {string} req.newPassword
 */
export async function editUserPassword(userId, req) {
  return put(`/users/${userId}/password`, req).catch(reject);
}

/**
 * Get notifications.
 */
export async function getNotifications() {
  return get("/person/notifications")
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Get unread notifications.
 */
export async function getNotificationsUnread() {
  return get("/person/notifications/unread")
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Sets notifications as seen.
 */
export async function setNotificationsSeen() {
  return get("/person/notifications/seen")
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Sets notifications as seen.
 */
export async function getInvoices() {
  return get("/invoice")
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Sets notifications as seen.
 */
export async function getInvoice(invoiceId) {
  return get(`/invoice/${invoiceId}`)
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Gets oil total.
 */
export async function getOilTotal() {
  return get("/visitor/oil-total")
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Starts user recovery process.
 *
 * @param {string} email
 */
export async function startUserRecovery(email) {
  return post("/user-recoveries", { email })
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Completes user recovery process.
 *
 * @param {string} code
 * @param {string} password
 */
export async function completeUserRecovery(code, password) {
  return post(`/user-recoveries/${code}`, {
    password,
  }).catch(reject);
}

/**
 * Gets user recovery process status.
 *
 * @param {string} code
 */
export async function getUserRecoveryStatus(code) {
  return get(`/user-recoveries/${code}`)
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Visitor password link status.
 */
export async function isCardTokenValid(token) {
  return post("/visitor/card-token/status", { token })
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Get single request by id.
 */
export async function getRequest(requestId) {
  return get(`/requests/${requestId}`)
    .then((res) => res.data)
    .catch(reject);
}

/**
 * Edit user.
 *
 * @param {number} userId
 * @param {Object} body
 * @param {string} body.forename
 * @param {string} body.surname
 * @param {string} body.contactPhone
 * @param {string} body.contactEmail
 */
export async function editUser(userId, body) {
  return put(`/users/${userId}`, body)
    .then((res) => res.data)
    .catch(reject);
}

export async function stampOilRequestAsChoosingWinner(
  id: string,
  body: { comment: string },
) {
  return post(
    `/oil-requests/${id}/stamp-as-closed-or-choosing-winner`,
    body,
  ).catch(reject);
}
