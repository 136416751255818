import { Link } from "react-router-dom";
import Icons from "./sub/Icons";

const HeaderLight = ({ location }) => {
  if (location === "landing-page") {
    return (
      <div className="header-wrapper">
        <div className="header" style={{ paddingBottom: '0px' }}>
          <Link to="/">
            {Icons.TurgoilLogo}
          </Link>
          <Link to="/signin" className="header__login-landing">
            <div className="header__login-container" >
              <i className="bi bi-person header__icon-landing"></i>
              Logi sisse
            </div>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="header-wrapper">
      <div className="header">
        <Link to="/">
          {Icons.TurgoilLogo}
        </Link>
        {/**
          <Link to="/signin" className="header__login-other">
            <div className="header__login-container" >
              <i className="bi bi-person header__icon-other"></i>
              Logi sisse
            </div>
          </Link>
         */}
        <Link to="/signin" className="header__login-landing">
          <div className="header__login-container" >
            <i className="bi bi-person header__icon-landing"></i>
            Logi sisse
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HeaderLight;



