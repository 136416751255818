function Content2() {
    return (
        <div style={{ maxWidth: '850px' }}>
            <div className="mobileImg">
                <img style={{ maxWidth: '850px', width: '100%' }} src="../../../images/png/Jaagumae_img1.png" alt="Img1" />
            </div>
            <div style={{ fontSize: '14px' }}>Foto: Jaagumäe erakogu</div>
            <h1 className="title">
                Jaagumäe OÜ kogemus: Turgoili platvorm säästab kütuste
                hankimisel aega ja närvikulu
            </h1>
            <div className="content">
                <div>
                    04.01.2025
                </div>
                <div className="subTitle">
                    Jaagumäe OÜ peab Turgoili pilootprojektis osalemist igati meeldivaks
                    ettevõtmiseks, sest platvorm oli juba alustamise hetkel hästi
                    organiseeritud ning kasutajatagasisidet võeti kiireimas korras kuulda. Kõik see
                    on soodustanud järgnevate hangete korraldamist, mida põllumajanduses
                    tegutsev ettevõte oskab oma tööiseloomu arvestades kõrgelt hinnata.
                </div>
                <div>
                    Jaagumäe OÜ tegeleb Võru ja Põlva maakonnas peamiselt põllumajandussaaduste
                    tootmisega ning selle väärindamisega siseturul. Põhilisteks kasvatatavateks
                    kultuurideks on kombainiga koristatavad teraviljad, kaunviljad ja raps, samuti
                    tegeletakse Eesti kontekstis suures koguses kartuli ja erinevate köögiviljade
                    kasvatamise, töötlemise ja turustamisega. Lisaks põllumajanduskultuuridele tegeleb
                    ettevõte ligi 3000 pealise seakarja kasvatamisega, mille põhiline toodang
                    realiseeritakse tütarfirma kauplustes kohalikus Võru linnas.
                </div>
                <div>
                    Agronoom Sten-Robin Käosi sõnul on kütuse olemasolu ettevõttele aastaringselt
                    kriitilise tähtsusega, kuna põllutöödel, eriti saagikoristusel, on kultuuridest sõltuvalt
                    väga väikesed ajaaknad tööde läbiviimiseks.
                </div>
                <div>
                    «Põllumeestele valmistavad enim raskusi kiiretel perioodidel nii ilmastikust kui ka
                    tehnikaga seotud probleemidest tingitud takistused, mistõttu ei tohi ettevõte lubada
                    endale tööde seiskumist kütuse puudumise tõttu. Samuti on kütus hädavajalik
                    toodetud kauba ja saaduste transpordiks kokkulepitud tähtaegadeks üle Eesti,» ütleb
                    Käos.
                </div>
                <div>
                    Kuigi ettevõte tarbib aastas hinnanguliselt 700 000 liitrit diisekütust, siis enim kulub
                    seda kevadiste ja varasügiseste põllutööde ajal. Aastaaegadest ning tehnika
                    vajadustest sõltub ka kütuse varumise ja hoiustamise viis.
                </div>
                <div className="subTitle">
                    Lihtsuses peitub võlu
                </div>
                <div>
                    Sten-Robin Käos möönab, et enne Turgoili platvormi kasutamist ei nõudnud
                    pakkumiste küsimine ülemäärast pingutust, kuid kiiretel perioodidel võis 5-6 tarnijaga
                    suhtlemine olla väga tülikas. «Pidev edasi-tagasi helistamine pakkujate vahel tagas
                    küll soodsaima hinna, kuid selleks kuluv aeg ja tähelepanu nõudsid oma osa teiste
                    probleemidega tegelemise ees,» kinnitab Käos.
                </div>
                <div className="subTitle quote">
                    «Turgoili platvormil teostatud kütusehanked on säästnud ettevõtte juhatusele väärtuslikku
                    aega ja pakkunud rahulolutunnet, jättes ära pidevad helistamised erinevate tarnijate
                    vahel.»
                </div>
                <div>
                    Lisaks pälvib põllumajanduses tegutseva ettevõte hinnangul kiidusõnu platvormi
                    kasutajasõbralikkus. «Olemuselt lihtne asi on suudetud hoida lihtsana: lisatud on
                    vaid vajalikud lahtrid ning informatsioon, mida hanke korraldamiseks ja võitja
                    valimiseks läheb vaja. Kiitusena tasub välja tuua Turgoili meeskonna valmidust
                    kiiresti reageerida kasutaja tagasisidele.»
                </div>
                <div className="subTitle">
                    Suurepärane kasvulava
                </div>
                <div>
                    Üldiselt tõdeb Käos, et Turgoili platvormil teostatud kütusehanked on säästnud
                    ettevõtte juhatusele väärtuslikku aega ja pakkunud rahulolutunnet, jättes ära pidevad
                    helistamised erinevate tarnijate vahel. Lisaks aitavad Turgoil keskkonnas läbiviidud
                    hanked paremini planeerida kütuse ostu- ja vastuvõtutingimusi.
                </div>
                <div>
                    Kütusepakkumised ja suhtlus pakkujatega on suures osas vastanud Jaagumäe OÜ
                    ootustele. «Enim üllatas pakkujate kiirus vastata hangetele, mis seab paremaid
                    tingimusi korraldada hankeid lühema ajaperioodi vältel või kui kütuse tellimisega on
                    väga kiire.» selgitab Käos.
                </div>
                <div>
                    Edasise arengu poolelt ootab ettevõte Turgoililt suuremat pakkujate ringkonda, et
                    igal korraldataval hankel osaleks võimalikult palju kütusepakkujaid.
                </div>
                <div>
                    «Näen Turgoilil suurepärast kasvulava Eesti turul, sest tänapäeva järjest kiirenevas
                    elutempos mängivad suurt rolli aega ja närvikulu säästvad projektid. Samuti aitab
                    taoliste platvormide olemasolu kaasa konkurentsivõimele kütuseturul,» ütleb Käos.
                </div>
            </div>
        </div>
    );
}

export default Content2;