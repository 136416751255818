function Content1() {
    return (
        <div style={{ maxWidth: '850px' }}>
            <div className="mobileImg">
                <img style={{ maxWidth: '850px', width: '100%' }} src="../../../images/png/EK_img1.png" alt="Img1" />
            </div>
            <div style={{ fontSize: '14px' }}>Foto: Eesti Killustik / Erlend Štaub</div>
            <h1 className="title">
                Eesti Killustiku kogemus: Vedelkütuste hulgiturul on revolutsioonihõngulised ajad
            </h1>
            <div className="content">
                <div>
                    15.12.2024
                </div>
                <div className="subTitle">
                    Eesti Killustik OÜ osales vedelkütuste hankeplatvormi Turgoil pilootprojektis,
                    mis on karjäärides tegutsevale ettevõttele olnud suur võimalus panustada
                    valdkonna uuendustesse ning aidata seeläbi muuta kütuseturgu läbipaistvamaks.
                    Konkurentsist tulenev soodne kütuse hind on neile olnud meeldivaks boonuseks.
                </div>
                <div>
                    Kahtlemata on Eesti Killustiku jaoks oluline, millise hinna eest
                    ning kui operatiivselt saab vedelkütuseid hulgikogustes hankida.
                    Ettevõtte masinapargis on 16 laadurit, 10 ekskavaatorit ja 20
                    ühikut muud diiselkütusel töötavat tehnikat. Lisaks 14 veokit,
                    mille hulgas on nii kallurid kui ka paak- ja tankurautod.
                    Mõistetavalt võtavad Eesti eri paigus – Kuusalust kuni Kaatsi
                    liivakärjäärini – tegutsevad raskesõidukid suures koguses kütust.
                </div>
                <div>
                    Eesti Killustiku karjäärimeistri Ardo Virkebau sõnul tarbivad
                    nad hinnanguliselt 1 000 000 liitrit kütust aastas, mis on ligi 20
                    protsenti tootmise otsekulust.
                </div>
                <div>
                    Samas tõdeb Virkebau, et enne Turgoili pilootprojektis osalemist viisid
                    nad kütusehankeid läbi harva. Enamasti tehti seda mõne aasta tagant
                    ning siis valiti oma kütuse vajaduse rahuldamiseks kindel koostööpartner.
                    «Kütusehinna kujunemine on keeruline. Tegelikkuses puudus meil võimalus
                    kontrollida lepingu sisulist täitmist. Oleme ka elektrihinna ostmisel
                    börsihinna usku olnud ning selleks julgesime katsetada süsteemi,
                    kus kütuse hind kujuneb iga ostu puhul turult,» selgitab Virkebau.'
                </div>
                <div className="subTitle">
                    Turg paneb asja paika
                </div>
                <div>
                    Eesti Killustiku jaoks on Turgoili vedelkütuste hankeplatvormi
                    pilootprojektis osalemine olnud suur au, sest nii on nad saanud
                    anda oma panuse kütuseturuga seotud valdkonna uuendustesse ning
                    kogu hankeprotsessi paremaks ja läbipaistvamaks muutmisel. Virkebau
                    sõnul on nad seeläbi nii endale kui ka koostööpartneritele loonud
                    ausa konkurentsi võimaluse. Nii saavad nad tarnijate valiku puhul
                    hinnata nende pakutud terviklahendusi ning suutlikkust transportida
                    kütust vastavalt Eesti Killustiku tegelikele vajadustele.
                    <b> Soodne hind on sellisel juhul vaid boonuseks.</b>
                </div>
                <div>
                    <div>
                        Kütuse müüja saab jällegi oma logistilisi marsruute paremini optimeerida.
                        Virkebau teab rääkida, et olenevalt päevast ja asukohast erinevad kütuse müüjate
                        pakkumised teineteisest olulisel määral.
                    </div>
                </div>
                <div className="subTitle quote">
                    «Oleme ka elektrihinna ostmisel börsihinna usku olnud
                    ning selleks julgesime katsetada süsteemi,
                    kus kütuse hind kujuneb iga ostu puhul turult.»
                </div>
                <div>
                    Ka Turgoli loodud veebikeskkonna kasutamise osas leiab Virkebau
                    kiidusõnu. «Platvorm on lihtne, kergesti kasutatav ja kasutajasõbralik.
                    Hea on see, et platvormi haldaja on innovaatiline ja arvestav.
                    Uuendused ja parandused lähevad töösse koos hanke
                    korraldajate ja tarnijate soovitusi arvestades. Pilootprojektis on
                    hea osaleda ka selleks, et siis saad platvormi arendusel rohkem
                    kaasa rääkida kui hilisemad liitujad» ütleb Virkebau.
                </div>
                <div>
                    Lisaks hoiab Eesti Killustik platvormi kasutades aega kokku ning annab
                    võimaluse teha analüüse kütusehindade muutuste kohta ajas.
                </div>
                <div className="subTitle">
                    Revolutsioon kütuseäris
                </div>
                <div>
                    Karjäärimeistri Ardo Virkebau hinnangul on suhtlus ja Turgoili
                    keskkonna kaudu edastatavad pakkumised olnud ootuspärased.
                    Mõned üksikud kütusemüüjad eristuvad teistest.
                    «On tunda, et paremad ikkagi on orienteeritud eelkõige
                    kliendi maksimaalse rahulolu tagamisele, mitte kommenteerides
                    või halvusatades konkurente. Üheskoos suudab igaüks rohkem ja
                    sedasi on partnerid üksteisele maksimaalselt kasulikud.» ütleb Virkebau.
                </div>
                <div>
                    Üldiselt usub Virkebau, et sellised platvormid nagu Turgoil on Eesti
                    kütuseturul revolutsioonihõngulised. Kui neid edasi
                    arendada ning tegevussuundi laiendada, säästavad nad tarbija raha.
                </div>
            </div>
        </div>
    );
}

export default Content1;