import { Link, useParams } from "react-router-dom";
import Content1 from "./sub/Content1";
import { Helmet } from "react-helmet";
import HeaderLight from "../../common/HeaderLight";
import Footer from "../../common/Footer";
import Section from "../../common/Section";
import Content2 from "./sub/Content2";
import ContentLanding from "./sub/ContentLanding";

const contentComponents = {
    undefined: ContentLanding,
    1: Content1,
    2: Content2
};

function CustomerStoriesPage() {
    let { id } = useParams();

    const ContentComponent = contentComponents[id];
    return (
        <div className="customer-stories">
            <HeaderLight location="other" />
            <Helmet>
                <title>Turgoil - Kliendilood</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
            </Helmet>
            <Section backgroundColor="#1a273d33">
                <div className="information-page__title">
                    <Link to="/article/et-EE/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Kliendilood
                    </Link>
                </div>
            </Section>
            <Section backgroundColor="#fff">
                <div>
                    {
                        ContentComponent ? <ContentComponent /> : <p>Content not found.</p>
                    }
                </div>
            </Section>
            <Footer rootFolder="../../../" />
        </div>
    );
}
export default CustomerStoriesPage;