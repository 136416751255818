import PropTypes from "prop-types";
import HeaderLight from "../HeaderLight";
import Footer from "../Footer";
import Logos from "../Logos";
import Section from "../Section";
// import { ReactComponent as Logo } from '../../images/svg/turgoil-logo-mono.svg';

export const PictureLayoutForm = (props) => (
  <>
    <div className="layout">
      <HeaderLight location="other"/>
      <Section backgroundColor="#fff">
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <div className="loginContainer">
            {props.children}
          </div>
        </div>
      </Section>
      <Footer rootFolder={""}/>
    </div>
  </>
);

PictureLayoutForm.propTypes = {
  children: PropTypes.node.isRequired,
};