import { ThunkDispatch } from "redux-thunk";
import * as api from "../services/turgoil-api";
import { RootState } from "./store";

const BIDDER_LOADING = "bidder/fetching";
const BIDDER_SUCCESS = "bidder/success";

export type Bidder = {
    id: number;
    registryCode: string;
    name: string;
    contract: boolean;
}

export type BidderState = {
  loading: boolean;
  bidders: Bidder[];
};

const initialState: BidderState = {
  loading: false,
  bidders: [],
};

export default function reducer(
  state = initialState,
  action: BidderSuccess | BidderLoading,
) {
  switch (action.type) {
    case BIDDER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case BIDDER_SUCCESS:
      return {
        bidders: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

type BidderLoading = {
  type: typeof BIDDER_LOADING;
};

type BidderSuccess = {
  type: typeof BIDDER_SUCCESS;
  payload: any;
};

export function biddersLoading(): BidderLoading {
  return { type: BIDDER_LOADING };
}

export function biddersSuccess(bidders: Bidder[]): BidderSuccess {
  return {
    type: BIDDER_SUCCESS,
    payload: bidders,
  };
}

export function getBidders() {
  return async (
    dispatch: ThunkDispatch<
      RootState,
      null,
      BidderLoading | BidderSuccess
    >,
  ): Promise<Bidder[]> => {
    dispatch(biddersLoading());
    return api.getBidders().then((res) => {
      dispatch(biddersSuccess(res.data.bidders));
      return res.data.bidders;
    });
  };
}
