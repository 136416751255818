const Icons = {
    TurgoilLogo: (
        <svg xmlns="http://www.w3.org/2000/svg" width="111" height="63" viewBox="0 0 111 63" fill="none">
            <g clipPath="url(#clip0_280_536)">
                <path d="M36.6186 49.7343C35.3862 53.5194 32.7454 56.9524 29.2244 59.3291C28.3442 59.8573 27.4639 60.4734 26.4956 60.8255C25.5274 61.1776 24.4711 61.4417 23.5028 61.7058C21.4782 62.1459 19.2776 62.2339 17.253 61.9699C13.1158 61.4417 9.06661 59.5932 6.07375 56.6883C2.99286 53.6955 0.880254 49.8224 0.264076 45.6852C0.0880254 44.6289 0.0880254 43.5726 0 42.5163C0 41.3719 0.176051 40.3156 0.440127 39.3473C0.880254 37.3228 1.49643 35.3862 2.20063 33.4496C3.60904 29.6646 5.28152 25.9675 7.13006 22.4465C10.7391 15.2284 14.6122 8.27439 18.7494 1.40841L19.5416 0L20.3339 1.40841L21.5662 3.43299C22.0063 4.13719 22.3584 4.75337 22.7105 5.45757L24.7351 9.68279L26.7597 13.908C27.4639 15.3164 27.9921 16.7248 28.6963 18.2213C27.0238 15.5805 25.3513 12.9397 23.6788 10.387L21.1261 6.42585C20.686 5.72165 20.2458 5.10547 19.8937 4.40127L19.5416 3.60904C19.5416 3.60904 12.4116 16.1967 8.89056 23.3267C7.13006 26.8477 5.45757 30.4568 4.04917 34.1538C3.34496 36.0024 2.72879 37.8509 2.37669 39.6994C2.20063 40.6677 2.02458 41.548 2.02458 42.4282C2.11261 43.3965 2.11261 44.3648 2.28866 45.245C2.81681 49.0301 4.75337 52.4631 7.48216 55.1039C10.2109 57.7446 13.82 59.4171 17.517 59.8573C19.3656 60.0333 21.3021 60.0333 23.1507 59.5932C24.119 59.4171 24.9992 59.1531 25.8795 58.801L27.1998 58.2728C27.64 58.0967 28.0801 57.9207 28.5202 57.6566C31.8652 56.1602 34.77 53.3434 36.6186 49.7343Z" fill="#DB9E21" />
                <path d="M22.2705 40.4037V29.4005H18.9255V27.816H22.3585L22.5346 22.8866H24.2951V27.816H29.2245V29.4005H24.2951V40.4917C24.2951 43.2205 24.9993 44.1007 27.2879 44.1007H29.4006V45.8612H26.9358C23.5909 45.7732 22.2705 44.1887 22.2705 40.4037ZM33.2737 39.6994V27.728H35.2102V39.4354C35.2102 42.7803 36.5306 44.3648 39.2594 44.3648C41.8121 44.3648 43.8367 42.9564 45.3331 39.6114V27.728H47.2697V45.7732H45.5972L45.5092 42.2522C43.9247 44.8929 41.8121 46.2133 38.9073 46.2133C35.2102 46.2133 33.2737 43.9247 33.2737 39.6994ZM51.9351 27.728H53.6075L53.6956 31.4251C54.7519 28.6083 56.5124 27.3759 59.3292 27.3759H60.4735V29.3125H59.5052C56.4243 29.3125 54.6638 30.9849 53.8716 34.8581V45.7732H51.9351V27.728ZM63.4664 47.2696H65.3149C65.491 49.7343 67.4275 50.8787 70.5964 50.8787C74.4695 50.8787 76.23 49.2062 76.23 45.5091V41.724C74.6456 44.1887 72.445 45.4211 69.6281 45.4211C65.491 45.4211 63.1143 42.7803 63.1143 38.027V34.77C63.1143 30.0167 65.579 27.3759 69.6281 27.3759C72.533 27.3759 74.7336 28.6963 76.4061 31.337L76.4941 27.816H78.1666V45.5972C78.1666 50.3505 75.7019 52.6392 70.6845 52.6392C66.1952 52.6392 63.7304 50.7906 63.4664 47.2696ZM76.142 38.9072V33.8898C74.7336 30.8089 72.533 29.1364 69.8042 29.1364C66.7233 29.1364 65.0508 31.161 65.0508 34.9461V37.8509C65.0508 41.636 66.8113 43.6606 69.8042 43.6606C72.621 43.6606 74.7336 41.9881 76.142 38.9072ZM81.7757 38.4671V35.1221C81.7757 30.1047 84.4164 27.3759 89.4339 27.3759C94.3633 27.3759 97.0921 30.1047 97.0921 35.1221V38.4671C97.0921 43.4845 94.3633 46.2133 89.4339 46.2133C84.5044 46.2133 81.7757 43.4845 81.7757 38.4671ZM95.0675 38.291V35.2102C95.0675 31.161 93.1309 29.0484 89.4339 29.0484C85.7368 29.0484 83.8002 31.161 83.8002 35.2102V38.291C83.8002 42.3402 85.7368 44.4528 89.4339 44.4528C93.1309 44.4528 95.0675 42.3402 95.0675 38.291ZM100.613 21.1261H102.814V24.0309H100.613V21.1261ZM100.701 27.728H102.638V45.7732H100.701V27.728ZM108.975 20.0698H110.912V45.7732H108.975V20.0698Z" fill="#1A273D" />
            </g>
            <defs>
                <clipPath id="clip0_280_536">
                    <rect width="111" height="62.0579" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Icons;