import { Link } from "react-router-dom";

function ContentLanding() {
    return (
        <div style={{ maxWidth: '850px' }}>
            <div className="all">
                <div className="all__row">
                    <div className="all__row__item">
                        <div>
                            <Link to="/article/et-EE/1/eesti-killustiku-kogemus-vedelkutuste-hulgiturul-on-revolutsioonihongulised-ajad">
                                <img style={{ maxWidth: '400px', width: '100%' }} src="../../../images/png/EK_img1.png" alt="Img1" />
                            </Link>
                        </div>
                        <div className="all__row__item__title">
                            <Link to="/article/et-EE/1/eesti-killustiku-kogemus-vedelkutuste-hulgiturul-on-revolutsioonihongulised-ajad" style={{ textDecoration: 'none', color: 'inherit' }}>
                                Eesti Killustiku kogemus: Vedelkütuste hulgiturul on revolutsioonihõngulised ajad
                            </Link>
                        </div>
                        <div>
                            Eesti Killustik OÜ osales vedelkütuste hankeplatvormi Turgoil pilootprojektis,
                            mis on karjäärides tegutsevale ettevõttele olnud suur võimalus panustada valdkonna
                            uuendustesse ning aidata seeläbi muuta kütuseturgu läbipaistvamaks. Konkurentsist tulenev
                            soodne kütuse hind on neile olnud meeldivaks boonuseks.
                        </div>
                    </div>
                    <div className="all__row__item">
                        <div>
                            <Link to="/article/et-EE/2/jaagumae-kogemus-turgoili-platvorm-saastab-kutuste-hankimisel-aega-ja-narvikulu" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <img style={{ maxWidth: '380px', width: '100%' }} src="../../../images/png/Jaagumae_img1.png" alt="Img1" />
                            </Link>
                        </div>
                        <div className="all__row__item__title">
                            <Link to="/article/et-EE/2/jaagumae-kogemus-turgoili-platvorm-saastab-kutuste-hankimisel-aega-ja-narvikulu" style={{ textDecoration: 'none', color: 'inherit' }}>
                                Jaagumäe OÜ kogemus: Turgoili platvorm säästab kütuste hankimisel aega ja närvikulu
                            </Link>
                        </div>
                        <div>
                            Jaagumäe OÜ peab Turgoili pilootprojektis osalemist igati meeldivaks
                            ettevõtmiseks, sest platvorm oli juba alustamise hetkel hästi
                            organiseeritud ning kasutajatagasisidet võeti kiireimas korras kuulda.
                            Kõik see on soodustanud järgnevate hangete korraldamist, mida
                            põllumajanduses tegutsev ettevõte oskab oma tööiseloomu arvestades kõrgelt hinnata.
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
}

export default ContentLanding;